var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.message
    ? _c(
        "section",
        [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "div",
              { staticClass: "ml-0 pl-0", staticStyle: { width: "12rem" } },
              [
                _c("h4", { staticClass: "m-0 p-0 mt-2 mb-1 link-header" }, [
                  _vm._v(_vm._s(_vm.$t("FROM"))),
                ]),
                _vm._v("\n      " + _vm._s(_vm.startTime) + "\n    "),
              ]
            ),
            _c(
              "div",
              { staticClass: "ml-0 pl-0", staticStyle: { width: "12rem" } },
              [
                _c("h4", { staticClass: "m-0 p-0 mt-2 mb-1 link-header" }, [
                  _vm._v(_vm._s(_vm.$t("TO"))),
                ]),
                _vm._v("\n      " + _vm._s(_vm.stopTime) + "\n    "),
              ]
            ),
          ]),
          _vm.recurrence
            ? _c("hr", { staticClass: "pb-0 mb-0 mt-2" })
            : _vm._e(),
          _vm.recurrence
            ? _c("PickRecurrence", {
                staticClass: "mt-2",
                attrs: {
                  recurrence: _vm.recurrence,
                  startDate: _vm.message.settings.meeting_start_date,
                  endDate: _vm.message.settings.meeting_end_date,
                  "text-only": "",
                },
              })
            : _vm._e(),
          _vm.attachments.length != 0
            ? _c("hr", { staticClass: "pb-0 mb-0 mt-2" })
            : _vm._e(),
          _vm.attachments.length != 0
            ? _c(
                "div",
                { staticClass: "mt-2" },
                _vm._l(_vm.attachments, function (item, index) {
                  return _c(
                    "span",
                    { key: index, staticClass: "ms-fontSize-12" },
                    [
                      _c("Attachment", {
                        attrs: {
                          item: item,
                          index: index,
                          message_uuid: _vm.message.message_uuid,
                          user_uuid: _vm.user_uuid,
                          filestorage: _vm.filestorage,
                          functionbox_uuid: _vm.functionbox_uuid,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _c("h4", { staticClass: "m-0 p-0 mt-2 mb-1 link-header" }, [
            _vm._v(_vm._s(_vm.$t("DESCRIPTION"))),
          ]),
          _c("BodyText", { attrs: { body: _vm.message.body } }),
          _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
          _c(
            "a",
            {
              staticClass: "mt-4 btn btn-fill btn-primary text-white",
              on: { click: _vm.OpenMeeting },
            },
            [_vm._v(_vm._s(_vm.$t("SECURELINK.TEAMS.JOIN_MEETING")))]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }