<template>
  <section v-if="message">
    <div class="d-flex">
      <div style="width: 12rem" class="ml-0 pl-0">
        <h4 class="m-0 p-0 mt-2 mb-1 link-header">{{ $t("FROM") }}</h4>
        {{ startTime }}
      </div>
      <div style="width: 12rem" class="ml-0 pl-0">
        <h4 class="m-0 p-0 mt-2 mb-1 link-header">{{ $t("TO") }}</h4>
        {{ stopTime}}
      </div>
    </div>

    <hr class="pb-0 mb-0 mt-2" v-if="recurrence" />

    <PickRecurrence class="mt-2"
      v-if="recurrence"
      :recurrence="recurrence"
      :startDate="message.settings.meeting_start_date"
      :endDate="message.settings.meeting_end_date"
      text-only
    ></PickRecurrence>

    <hr class="pb-0 mb-0 mt-2" v-if="attachments.length != 0" />

    <div class="mt-2" v-if="attachments.length != 0">
      <span
        v-for="(item, index) in attachments"
        :key="index"
        class="ms-fontSize-12"
      >
        <Attachment
          :item="item"
          :index="index"
          :message_uuid="message.message_uuid"
          :user_uuid="user_uuid"
          :filestorage="filestorage"
          :functionbox_uuid="functionbox_uuid"
        ></Attachment>
      </span>
    </div>

    <h4 class="m-0 p-0 mt-2 mb-1 link-header">{{ $t("DESCRIPTION") }}</h4>

    <BodyText :body="message.body"></BodyText>

    <hr class="pb-0 mb-0 mt-2" />

    <a @click="OpenMeeting" class="mt-4 btn btn-fill btn-primary text-white">{{
      $t("SECURELINK.TEAMS.JOIN_MEETING")
    }}</a>
  </section>
</template>
<script>
import PickRecurrence from "../../Input/PickRecurrence";
import Attachment from "../Attachment";
import BodyText from "../BodyText";
export default {
  name: "MessageInformation",
  props: [
    "filestorage",
    "message",
    "user_uuid",
    "functionbox_uuid",
    "startMeeting",
    "endMeeting",
    "recurrence"
  ],
  components: {
    Attachment,
    BodyText,
    PickRecurrence,
  },
  data() {
    return {};
  },
  methods: {
    async OpenMeeting() {
      try {
        let result = await this.$http.get(
          this.user.hostname + "/teams/join/" + this.message.message_uuid
        );
        if(this.teams.context != null)
        {
          if(this.teams.context.app.host.name.includes("Teams") && this.teams.context.app.host.clientType == "desktop")
          {
            setTimeout(() => {
              window.open(result.data.replace("https://","msteams://"), "_blank").focus();            
            });
          }else{
            setTimeout(() => {
              window.open(result.data, "_blank").focus();
            });
          }
        }else{
          setTimeout(() => {
            window.open(result.data, "_blank").focus();
          });
        }
      } catch {
        console.log("Could not OpenMeeting");
      }
    },
  },
  computed: {
    isFinished() {
      return this.current > this.expire;
    },
    expire() {
      let date = new Date(this.message.settings.open_to_date);
      return date;
    },
    current() {
      let date = new Date();
      return date;
    },
    attachments() {
      return this.message.attachments.filter((attachment) => {
        return attachment.name != "join_url.txt";
      });
    },
    createdTime() {
      return this.FormatDateTime(this.message.created_date);
    },
    createdName() {
      return (
        this.message.creator.name +
        ", " +
        this.message.creator.organisation_name
      );
    },
    startTime(){
      if(this.startMeeting == null)
      {
        return this.FormatDateTime(this.message.settings.meeting_start_date) 
      }
      const utcISOString = new Date(this.startMeeting).toISOString();
      return this.FormatDateTime(utcISOString);
    },
    stopTime(){
      if(this.endMeeting == null)
      {
        return this.FormatDateTime(this.message.settings.meeting_end_date) 
      }
      const utcISOString = new Date(this.endMeeting).toISOString();
      return this.FormatDateTime(utcISOString);
    }
  },
  watch: {},
  mounted: function () {},
};
</script>
<style></style>